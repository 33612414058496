<template>
  <div class="video-comp" key="elementId" v-if="videoUrl != ''">
    <div class="video-compp">
      <i
        id="video-capture-list-open"
        class="fad fa-list"
        @click="openCapture()"
      ></i>
      <div class="video">
        <vimeo-player
          ref="videoPlayer"
          :autoplay="true"
          :video-url="videoUrl"
          @timeupdate="videoCaptureEvent"
          @playing="videoReady"
        />
      </div>
      <div class="capture" v-bind:class="isCapture ? 'active' : 'null'">
        <div class="capture-title">
          <h4>Video Parçacıkları</h4>
          <i class="far fa-times" @click="openCapture()"></i>
        </div>
        <div class="capture-content">
          <div
            class="capture-content-item"
            v-for="capture in videoCapture"
            :key="capture.id"
            @click="changeTime(capture)"
          >
            <p>{{ capture.title }}</p>
            <span v-bind:style="{ backgroundColor: getStatusColor(capture) }">{{
              capture.startTime
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="video-questions">
    <div class="video-questions-title">
      <h4>Video Soruları</h4>
    </div>
    <div class="video-questions-content">
      <div
        class="video-questions-content-item"
        v-for="question in videoQuestion"
        :key="question.id"
        @click="selectQuestion(question)"
      >
        <img :src="question.questionFile.url" alt="" />
      </div>
    </div>
  </div>
  <Modal
    v-model="isSolution"
    :fullscreen="false"
    modal-transition="scale"
    class="modal"
    :click-out="true"
    max-width="960px"
    :disable-motion="false"
    :remove-backdrop="false"
  >
    <div class="video-questions-modal">
      <ul>
        <li
          v-bind:class="isSolutionPanel == false ? 'active' : null"
          @click="isSolutionPanel = false"
        >
          Soru Resmi
        </li>
        <li
          v-bind:class="isSolutionPanel == true ? 'active' : null"
          @click="isSolutionPanel = true"
        >
          Videolu Cevap
        </li>
      </ul>
      <div class="question-box">
        <img
          v-if="!isSolutionPanel"
          :src="selectedQuestion.questionFile.url"
          alt=""
        />
        <div class="video" v-if="isSolutionPanel">
          <vimeo-player
            ref="questionPlayer"
            v-if="isSolution"
            :video-url="selectedQuestion.solutionFile.url"
            @playing="questionPlayerReady"
          />
        </div>
      </div>
    </div>
  </Modal>
  <Modal
    v-model="isShowQuestionSec"
    :fullscreen="false"
    modal-transition="scale"
    class="modal"
    :click-out="false"
    :disable-motion="false"
    :remove-backdrop="false"
  >
    <div class="video-questions-modal">
      <h1>İzlemeye Devam Ediyormusun?</h1>
      <ul>
        <li style="flex: 1" class="active" @click="playHomeWork()">Devam Et</li>
        <li style="flex: 1" @click="stopHomeWork()">Kapat</li>
      </ul>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "vue-neat-modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      elementId: "",
      isCapture: false,
      videoData: {},
      videoUrl: "",
      videoQuestion: [],
      videoCapture: [],
      selectedQuestion: {},
      isSolution: false,
      isSolutionPanel: false,
      time: 0,
      sendPercent: -1,
      isInit: false,
      isInitSolution: false,
      homeWorkId: null,
      isHomeWorkInitTime: 0,
      isShowQuestionSec: false,
    };
  },
  created() {
    this.init();
  },
  watch: {
    "$route.params.elementId": function (newData, oldData) {
      if (oldData != null && this.$route.name == "video") {
        this.init();
      }
    },
  },
  computed: {
    player() {
      return this.$refs.videoPlayer;
    },
    getPlayer() {
      return this.$refs.videoPlayer.player;
    },
    solutionPlayer() {
      return this.$refs.questionPlayer;
    },
  },
  methods: {
    init() {
      this.elementId = this.$route.params.elementId;
      this.homeWorkId = this.$route.query.homeWorkId;
      this.sendPercent = -1;
      this.isInit = false;
      this.getData();
      this.getQuestions();
      this.getCapture();
    },
    questionPlayerReady() {
      console.log("Geldim", this.selectedQuestion.startTime);
      if (this.isInitSolution == false) {
        //setCurrentTime
        var hms = this.selectedQuestion.startTime;
        var a = hms.split(":");
        var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        var player = this.$refs.questionPlayer.getPlayer();
        player.setCurrentTime(seconds);
        this.isInitSolution = true;
      }
    },
    openCapture() {
      this.isCapture = !this.isCapture;
    },
    async videoReady() {
      if (this.isInit == false) {
        var time = this.$route.query.time;

        var player = this.$refs.videoPlayer.getPlayer();
        if (time == undefined) {
          this.$http
            .get("Student/VideoTracking/" + this.elementId)
            .then((seconds) => {
              player.setCurrentTime(parseFloat(seconds.data.data));
            });
        } else {
          player.setCurrentTime(parseFloat(time));
        }
        this.isInit = true;
      }
    },
    getData() {
      console.log("video-getdata");
      this.$center.emit("Loading", "show");
      this.$http
        .get("Student/Element/Video/" + this.elementId)
        .then((x) => {
          this.$center.emit("TitleChanged", x.data.data.name);
          this.videoData = x.data.data;
          this.videoUrl = x.data.data.file.url;
          console.log(this.videoUrl);
          this.$center.emit("Loading", "hide");
        })
        .catch(() => {
          this.$center.emit("Loading", "hide");
        });
    },
    getQuestions() {
      this.$http
        .get("Student/VideoQuestion/" + this.elementId)
        .then((x) => {
          this.videoQuestion = x.data.data;
        })
        .catch(() => {
          //alert(error);
        });
    },
    getCapture() {
      this.$http
        .get("Student/VideoCapture/" + this.elementId)
        .then((x) => {
          this.videoCapture = x.data.data;
        })
        .catch(() => {
          //alert(error);
        });
    },
    selectQuestion(question) {
      console.log(question);
      this.selectedQuestion = question;
      this.isSolutionPanel = false;
      this.isSolution = true;
      this.isInitSolution = false;
    },
    getStatusColor(capture) {
      var cutTime = capture.startTime.split(":");
      var totalSecond =
        parseInt(cutTime[0] * 3600) +
        parseInt(cutTime[1] * 60) +
        parseInt(cutTime[2]);
      if (totalSecond <= this.time) {
        return "#17bf63";
      } else {
        return "#aaaaaa";
      }
    },
    async videoCaptureEvent(e) {
      this.time = e.seconds;
      var percent = parseFloat(e.percent * 100);
      if (percent % 5 == 0) {
        if (this.sendPercent != percent) {
          this.sendPercent = percent;
          this.$http.put("Student/VideoTracking", {
            videoId: this.elementId,
            time: e.seconds,
            percent: percent,
          });
        }
      }

      this.getHomeWorkStatus(e);
    },
    changeTime(capture) {
      console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
      console.log(this.$refs.videoPlayer);
      var cutTime = capture.startTime.split(":");
      var totalSecond =
        parseInt(cutTime[0] * 3600) +
        parseInt(cutTime[1] * 60) +
        parseInt(cutTime[2]);
      var player = this.$refs.videoPlayer.getPlayer();
      player.setCurrentTime(totalSecond);
    },
    getHomeWorkStatus(time) {
      var duration = time.duration;
      var second = time.seconds;
      if(this.homeWorkId == null || this.homeWorkId==undefined)
            return;
      if (this.isHomeWorkInitTime == 0) {
        this.isHomeWorkInitTime = parseInt(
          Math.floor(
            Math.random() * (Math.floor(duration) - Math.ceil(second) + 1)
          ) + Math.ceil(second)
        );
      } else if (this.isHomeWorkInitTime <= second) {
        this.isShowQuestionSec = true;
        this.pauseVideo();
      }
    },
    pauseVideo() {
      var player = this.$refs.videoPlayer.getPlayer();
      player.pause();
    },
    playHomeWork() {
      var player = this.$refs.videoPlayer.getPlayer();
      player.play();
      this.isShowQuestionSec = false;
      this.isHomeWorkInitTime = 19999999999;
      //ev ödevi yapıldı olarak işaretle
      this.$http
        .post("Student/HomeWorkWatch/" + this.homeWorkId)
        .then((x) => {})
        .catch(() => {});
    },
    stopHomeWork() {
      this.$router.push("/");
    },
  },
};
</script>
