<template>
<div class="content messages">

    <div class="con">
        <div class="message-list">
            <button class="message-list-item" v-for="group in groups" :key="group.id" @click="selectGroup(group)">
                <div class="cover">
                    <img :src="group.imageUrl" alt="">
                </div>
                <div class="info">
                    <h4>{{group.name}}</h4>
                    <p>Bu gruba mesaj atabilirsiniz.</p>
                </div>
            </button>
        </div>

        <div id="msg-content" class="message-content" style="right: -100%;">
            <div class="top">
                <i class="fas fa-chevron-left" @click="getBack()"></i>
                <img :src="selectedGroup.imageUrl??'https://www.vhv.rs/dpng/d/601-6016613_info-icon-png-transparent-png.png'" alt="">
                <h4>{{selectedGroup.name??'Mesaj Atmak İstediğiniz Grubu Seçin'}}</h4>
            </div>
            <div class="main" id="message-manin">
                <div class="message-box" v-bind:class="msg.clientId==userId?'message-box-me':null" v-for="msg in messages" :key="msg">
                    <div class="cover">
                        <img :src="msg.profileImage" alt="">
                    </div>
                    <div class="info">
                        <span>{{msg.messageDate}}</span>
                        <h5>{{msg.userName}}</h5>
                        <p>{{msg.messageText}}</p>
                        <img :src="msg.messageImage" style="max-width: 100%;" />
                    </div>
                </div>
            </div>
            <div class="bot">
                <div class="img-add" v-if="addMessageRequest.imageUrl!=''">
                    <img :src="addMessageRequest.imageUrl" alt="">
                    <span>Resim Seçildi</span>
                    <i class="far fa-times" style="font-size: 24px; margin-left: auto;" @click="clearImage"></i>
                </div>
                <div class="bot" style="flex-wrap: nowrap;">
                    <i class="fad fa-camera-alt" @click="addImage"></i>
                    <input ref="fileInput" @change="handleImage" accept="image/*" type="file" style="display:none" />
                    <input type="text" v-model="addMessageRequest.text" placeholder="Mesaj yazın" v-on:keyup.enter="addMessage">
                    <i class="fad fa-paper-plane" @click="addMessage"></i>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr'
var emitter = require('tiny-emitter/instance');
export default {
    data() {
        return {
            groups: [],
            messages: [],
            selectedGroup: {},
            addMessageRequest: {
                messageGroupId: '',
                text: '',
                imageUrl: ''
            },
            connection: null,
            userId: this.$cookies.get('UserData').id
        }
    },
    created() {
        const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.uzaktanokulsistemi.com/chatHub' : 'https://localhost:5001/chatHub';
        this.connection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Information)
            .withUrl(baseURL, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .build()

        this.connection.start()
        this.getData();
    },
    unmounted() {
        this.connection.stop()
    },
    updated() {
        this.$nextTick(() => {
            var container = this.$el.querySelector("#message-manin");
            container.scrollTop = container.scrollHeight + 10;
        });
    },
    methods: {
        clearImage() {
            this.addMessageRequest.imageUrl = "";
            this.$refs.fileInput.value = null;
        },
        addImage() {
            this.$refs.fileInput.click()
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            this.getBase64(selectedImage);
        },
        getBase64(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            var scoped = this;
            reader.onload = function () {
                scoped.addMessageRequest.imageUrl = reader.result;
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        },
        async getData() {
            var groups = await this.$http.get("Message/Groups");
            this.groups = groups.data.data;
            if (this.groups.length > 0) {
                this.selectedGroup = this.groups[0];
                this.getMessages();
            }
            this.groups.forEach(element => {
                this.listener(element.id);
            });
        },
        getBack() {
            var content = document.getElementById("msg-content");
            content.style.right = "-100%";
        },
        selectGroup(data) {
            this.selectedGroup = data;
            this.getMessages();
            var content = document.getElementById("msg-content");
            content.style.right = "0px";
        },
        async getMessages() {
            var messages = await this.$http.get("Message/Messages/" + this.selectedGroup.id);
            this.messages = messages.data.data;
        },
        async addMessage() {
            if (this.addMessageRequest.text == "" && this.addMessageRequest.imageUrl == "")
                return;

            this.addMessageRequest.messageGroupId = this.selectedGroup.id;
            this.$http.post("Message/AddMessage", this.addMessageRequest).then(() => {
                this.addMessageRequest.text = "";
                this.addMessageRequest.imageUrl = "";
                this.$refs.fileInput.value = null;
                this.getMessages();
            })
        },
        listener(Id) {
            this.connection.on(Id, (messageData) => {
                if (messageData.channelId == this.selectedGroup.id) {
                    this.messages.push(messageData);
                } else {
                    var groups = this.groups.filter(x => x.id == messageData.channelId)[0];
                    console.log(groups);
                    emitter.emit('alert', {
                        icon: messageData.profileImage,
                        title: messageData.userName + "-" + groups.name,
                        description: messageData.messageText == "" ? 'Resim gönderdi' : messageData.messageText,
                        type: 'success'
                    });
                }
                if (messageData.clientId != this.userId) {
                    var audio = new Audio("https://dosya.turkiyeakademi.net/message.mp3");
                    audio.play();
                }
            });
        }
    },
}
</script>
