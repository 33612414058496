<template>
<badge-popup v-if="isShow" />
<component :is="layout" />
<settings-modal />
<router-view />
<notification-manager />
</template>

<script>
import Header from "./components/Header"
import WatchHeader from "./components/LessonWatchHeader"
import ExamHeader from "./components/ExamHeader"
import SettingsModal from "./components/SettingModal"
import NotificationManager from "./components/Notification/Notification"
const html = document.getElementsByTagName("html")[0];
const body = document.getElementsByTagName("body")[0];
const app = document.getElementById("app");
import BadgeAlert from "./components/Modal/BadgePopup"

import firebase from "firebase";
import "firebase/messaging";

const overlay = document.getElementById("general-overlay");
var emitter = require('tiny-emitter/instance');
export default {
    name: 'App',
    components: {
        "header-layout": Header,
        "watching-layout": WatchHeader,
        "exam-layout": ExamHeader,
        "settings-modal": SettingsModal,
        "notification-manager": NotificationManager,
        "badge-popup": BadgeAlert
    },
    data() {
        return {
            isShow: false,
            messaging: firebase.messaging(),
        }
    },
    methods: {
        receiveMessage() {
            try {
                this.messaging.onMessage((payload) => {
                    var notification = payload.notification;
                    console.log(notification);
                    this.$center.emit("alert", {
                        icon: notification.icon,
                        title: notification.title,
                        description: notification.body,
                        type: 'danger'
                    });
                });
            } catch (e) {
                console.log(e);
            }
        },
        onresize(event) {
            var width = event.currentTarget.innerWidth;
            if (width <= 1200) {
                body.classList.remove("vertical");
                body.classList.remove("horizontal");
                body.classList.add("vertical");
            } else {
                body.classList.remove("vertical");
                body.classList.remove("horizontal");
                body.classList.add(html.dataset.default);
            }
        },
        ondrawMenu() {
            emitter.emit('MenuOpen', 'close');
        },
        changer(type) {
            if (type != null) {
                app.style.flexDirection = type;
            } else {
                app.style.flexDirection = null;
            }
        }
    },
    async updated() {
        if (this.$cookies.get("Token") != null) {
            var visitBadgeResponse = await this.$http.get("Student/VisitBadge");
            var visitBadge = visitBadgeResponse.data.data;
            if (visitBadge != null) {
                this.isShow = true;
            }
            var visitSurveyResponse = await this.$http.get("Student/VisitSurvey");
            var visitSurvey = visitSurveyResponse.data.data;
            if (visitSurvey && this.$route.name != "WatchSurvey") {
                this.$router.push({
                    name: "Survey"
                });
            }
        }
    },
    async created() {
        const userData = this.$cookies.get("UserData");
        if (userData != null) {
            window.ym(75526678, 'userParams', {
                "userId": userData.id,
                "firstName": userData.firstName,
                "lastName": userData.lastName,
                "email": userData.email
            });
            firebase
                .messaging()
                .requestPermission()
                .then(() => {
                    return firebase
                        .messaging()
                        .getToken()
                        .then(async (token) => {
                            await this.$http.put("Auth/Device", {
                                token: token,
                                osType:'Web'
                            });
                            fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/general', {
                                method: 'POST',
                                headers: new Headers({
                                    'Authorization': 'key=AAAAw4j1o9M:APA91bEakyfnPpcA8ew8AXSktJKgyp40HHB3tRw4cPRWozMiXVdbWcjKGOiP6J3sqvzzLc_LuToO8kQLyClmSbDolbmI2jsgwMpgjvvcCgsj1vdk85sqGXmWUKKJKrSO25Jg7vD8wg2_'
                                })
                            }).then(response => {
                                if (response.status < 200 || response.status >= 400) {
                                    throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
                                }
                            }).catch(error => {
                                console.error(error);
                            })
                        })
                        .catch((err) => {
                            console.log("Error", err);
                        });
                });
        }
    },
    mounted() {
        window.addEventListener("resize", this.onresize);
        overlay.addEventListener("click", this.ondrawMenu);

        emitter.on('Loading', function (arg1) {
            const appLoading = document.getElementById("loader");
            if (arg1 == "show") {
                if (appLoading) {
                    appLoading.style.display = "flex";
                }
            }
            if (arg1 == "hide") {
                if (appLoading) {
                    appLoading.style.display = "none";
                }
            }
        });
        emitter.on('MenuOpen', function (arg1) {
            console.log(arg1);
            var menuDraw = document.getElementsByClassName("header")[0];
            if (menuDraw.style.left == "0px") {
                menuDraw.style.left = "-300px";
                overlay.style.display = 'none';
            } else if (menuDraw.style.left == "-300px") {
                menuDraw.style.left = "0px";
                overlay.style.display = 'block';
            }
        });

        const userData = this.$cookies.get("UserData");
        if (userData != null) {
            //window.ym(75526678, 'setUserID', userData.id);
            window.ym(75526678, 'userParams', {
                "userId": userData.id,
                "firstName": userData.firstName,
                "lastName": userData.lastName,
                "email": userData.email
            });
            window.ym(75526678, 'getClientID', function (clientID) {
                console.log(clientID)
            });
            //fo(userData)
            this.$analytics.setUserId(userData.email);
            this.$analytics.setUserProperties(userData);
        }
    },
    unmounted() {
        window.removeEventListener("resize", this.onresize);
        overlay.removeEventListener("click", this.ondrawMenu);
    },
    computed: {
        layout() {
            if (this.$route.meta.layout === 'default') { this.changer(null); return 'header-layout'; }
            if (this.$route.meta.layout === 'watch') { this.changer(null); return 'watching-layout'; }
            if (this.$route.meta.layout === 'exam') { this.changer("column"); return 'exam-layout'; }
            return `null`
        }
    }
}
</script>

<style scoped>
</style>
