<template>
<div class="  working working-order" id="v-step-2"  > 
    <div class="title">
        <h2 style="font-size: 1em;
    font-weight: 700;">Ortalama Puan Sıralaması</h2>
    </div>
    <div class="ordercon">
 
         <div class="working-item" v-for="history in histories"  :key="history.user.id" >
            <div class="top">
                <div class="icon">
                   
                    <i class="fad fa-clipboard-list" ></i>
                </div>
                <div class="detail">
                    <h4><b v-html="history.rank">  </b> .  {{history.user.firstName}} {{history.user.lastName}}</h4>
                    <p>{{history.point}}</p>
                </div>
            </div>
            <div class="bottom">
         
                <div class="more">
                   
                </div>
            </div>
        </div>       
           
 

    </div>
</div>

</template>
 
<script>
import { copyProperties } from '@amcharts/amcharts4/.internal/core/utils/Object';
export default {
    data() {
        return {
            main: [],
            histories:[]
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {

            var main      = await this.$http.get("Student/Main");
            var history   = await this.$http.get("Student/GetStudentsExamHistory");
            var rank=1;
            this.histories  = history.data.data.filter(function(x){
                        
                        if(x.point=="NaN"){x.point=0;}
                        //else if(x.correct<200){x.point=0;}
                        //todo :  200 dogrusayısındanazları listeyealma
                        else{
                            x.point=x.point.toFixed(2);
                        }
                        return true;
                    }
             );
             
            this.histories.sort((a, b) =>( parseFloat(a.point) < parseFloat(b.point)? 1 : -1))
            this.histories  = this.histories.filter(function(x){
                    x.rank = rank++ ;
                    return true;
                }
            );

            var userData = this.$cookies.get("UserData");
            var   usersSelf = this.histories.findIndex(item => item.user.id === userData.id);
            console.log(this.histories[usersSelf].rank);
            var _this=this;
    
            this.histories  = this.histories.filter(function(x){
                    if((x.rank>_this.histories[usersSelf].rank-3 && x.rank<_this.histories[usersSelf].rank+3) ){
                        return true;
                    }else{
                        return false;
                    }
                }
            );
            
                usersSelf = this.histories.findIndex(item => item.user.id === userData.id);

            _this.histories[usersSelf].rank="<span style='color:orange;'><i>"+ _this.histories[usersSelf].rank + "</i></span>";
            this.main     = main.data.data;
            
        },
        openPage(data) {
            if (data.type == 'VIDEO') {
                this.$router.push('/watching/' + data.topicId + '/' + data.videoGroupId + '/video/' + data.refId);
            } else {
                this.$router.push({
                    name: "ExamPlayer",
                    params: {
                        examId: data.refId
                    }
                });
            }
        }
    }
}
</script>
<style scoped>
 .ordercon{

    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    overflow-x: none;
    overflow-y:auto;
    flex: 1;
    padding-top: 1em;
    padding-bottom: 1em;

 }
</style>