<template lang="tr">
<div class="content quizzes">
    <div class="sidebar" style="left: 100%" id="exam-filter">
        <div class="quizzes-title">
            <i class="far fa-chevron-left" @click="openFilter()"></i>
            <h2>Takvim Etkinlikleri</h2>
        </div>
        <div class="quizzes-filter">
            <div class="select-box">
                <h5>Takvim Etkinlikleri</h5>
                <label for="men">
                    <input type="radio" id="men" name="gender" @change="selectedType('ALL')" />
                    <span></span>
                    <p> Tüm Etkinlikler</p>
                </label>
                <label for="men">
                    <input type="radio" id="men" name="gender" checked @change="selectedType('SYLLABUS')" />
                    <span></span>
                    <p> Ders Programı</p>
                </label>
                <label for="woman">
                    <input type="radio" id="woman" name="gender" @change="selectedType('LIVE')" />
                    <span></span>
                    <p>Canlı Dersler</p>
                </label>
                <label for="none">
                    <input type="radio" id="none" name="gender" @change="selectedType('EXAM')" />
                    <span></span>
                    <p>Deneme Sınavları</p>
                </label>
                <label for="none">
                    <input type="radio" id="none" name="gender" @change="selectedType('HOMEWORK')" />
                    <span></span>
                    <p>Ev Ödevleri</p>
                </label>
                <label for="none">
                    <input type="radio" id="none" name="gender" @change="selectedType('WORK')" />
                    <span></span>
                    <p>Çalışma Zamanı</p>
                </label>
            </div>
            <filter-area css_class="select-box" @FilterExecute="filterExecute" />
        </div>
    </div>
    <div class="main">
        <full-calendar ref="calendarRef" :options="calendarOptions" class="full-calendar" />
    </div>

    <div class="filter-open-button" @click="openFilter()">
        <i class="far fa-filter"></i>
    </div>
    <Modal v-model="showDialog" :fullscreen="false" modal-transition="scale" :click-out="true" :disable-motion="false" max-width="500px" :remove-backdrop="false">
        <div class="theme-change" style="display: flex;max-width:500px">
            <div class="modal-header">
                <h3 class="title">{{selectedEvent.content}}</h3>
            </div>
            <div class="con">
                <h4>Başlama-Bitiş Tarihi</h4>
                <div class="horver">
                    <span class="active">{{ selectedEvent.start && selectedEvent.start }}</span>
                    <span class="active">{{ selectedEvent.end && selectedEvent.end }}</span>
                </div>
            </div>
        </div>
    </Modal>
</div>
</template>

<script>
import Calendar from "../components/Calendar"
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { Modal } from "vue-neat-modal";
import moment from 'moment'
export default {
    components: {
        Calendar,
        FullCalendar,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        interactionPlugin,
        Modal
    },
    data() {
        return {
            events: [],
            oldEvents: [],
            showDialog: false,
            selectedEvent: {},
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
                initialView: 'timeGridWeek',
                headerToolbar: {
                    start: 'sidebarToggle, prev,next, title',
                    end: 'dayGridMonth,timeGridWeek,listMonth',
                },
                editable: false,
                eventResizableFromStart: false,
                eventClick: this.handleEvent,
                dragScroll: false,
                dayMaxEvents: 3,
                locale: 'tr',
                navLinks: true,
                rerenderDelay: 350,
                buttonText: {
                    today: 'Bu Gün',
                    month: 'Ay',
                    week: 'Hafta',
                    day: 'Gün',
                    list: 'Liste'
                },
                events: []
            },
        }
    },
    created() {
        this.getData();
    },
    computed: {
        dateTime(value) {
            return moment(value).format("DD/MM/YYYY HH:MM");
            //05367751599
        },
    },
    methods: {
        eventClassName: function (arg) {
            return [
                `bg-light-primary`,
            ]
        },
        handleEvent: function (arg) {
            var event = arg.event;
            var data = this.oldEvents.filter(x => x.referanceId == event.id);
            if (data.length > 0)
                data = data[0];
            this.selectedEvent = data;
            this.showDialog = true;
        },
        openFilter() {
            var sideBar = document.getElementById("exam-filter");
            if (sideBar.style.left == "100%") {
                sideBar.style.left = "0";
            } else if (sideBar.style.left == "0px") {
                sideBar.style.left = "100%";
            }
        },
        async getData() {
            this.$center.emit('Loading', 'show');
            var events = await this.$http.get("Student/Calendars");
            this.events = events.data.data;
            this.oldEvents = events.data.data;
            var api = this.$refs.calendarRef.getApi();
            this.clearAllEvents();
            events.data.data.forEach(element => {
                api.addEvent({
                    id: element.referanceId,
                    title: element.content,
                    date: element.start,
                    end: element.end,
                    create: true
                });
            });
            api.refetchEvents();
            api.render();
            this.selectedType('SYLLABUS');
            this.$center.emit('Loading', 'hide');
        },
        clearAllEvents() {
            var api = this.$refs.calendarRef.getApi();
            var eventSource = api.getEvents();
            eventSource.forEach(event => {
                event.remove();
            });
        },
        selectedType(type) {
            if (type == 'ALL') {
                this.events = this.oldEvents;
            } else {
                var filteredData = this.oldEvents;
                filteredData = filteredData.filter(x => x.type == type);
                this.events = filteredData;
            }

            var api = this.$refs.calendarRef.getApi();
            this.clearAllEvents();
            this.events.forEach(element => {
                api.addEvent({
                    id: element.referanceId,
                    title: element.content,
                    date: element.start,
                    end: element.end,
                    create: true
                });
            });
            api.refetchEvents();
            api.render();
        }
    }
}
</script>

<style>
.full-calendar {
    height: calc(100% - 25px);
    margin-top: 25px;
}

.fc-event .bg-light-primary {
    background-color: var(--main-color-1);
}
</style>
