
const logoType = {
    TEXT: "text",
    IMAGE: "image"
}

//KURSSENLE 

//  var appInfo = {
//      name: 'KURSSENLE',
//      logo: 'img/logo_yatay-white.svg',
//      logoType: logoType.IMAGE,
//      api_key: "c193e8fe-3373-45ce-b20a-19c95494aaf2",
//      teachers: [
        
//      ]
//  } 
 //Pera Hayat Akademi 

 var appInfo = {
    name: 'PERA HAYAT AKADEMİ',
    logo: 'img/logo_yatay-pera.svg',
    logoType: logoType.IMAGE,
    api_key: "c193e8fe-3373-45ce-b20a-19c95494aaf9",
    teachers: [
       
    ]
} 

//Sevinç Koleji 

//  var appInfo = {
//      name: 'Sevinç Koleji',
//      logo: 'img/logo_yatay.svg',
//      logoType: logoType.IMAGE,
//      api_key: "c193e8fe-3373-45ce-b20a-19c95494aaf2",
//      teachers: [
//      ]
//  } 
 
//TÜRKİYE AKADEMİ
 
// var appInfo = {
//     name: 'TÜRKİYE AKADEMİ',
//     logo: 'img/logo.svg',
//     logoType: logoType.TEXT,
//     api_key: "30653220-84a7-4412-8361-84b23b89cefe",
//     teachers: [
//     ]
// } 
 
export default appInfo;