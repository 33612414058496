<template>
<div class="content question-answer">

    <div class="question-answer-item question-answer-item-add" @click="addReply()">
        <h6>Yeni soru ekle</h6>
        <i class="far fa-plus"></i>
    </div>

    <div class="question-answer-item" v-for="reply in replys" :key="reply.id" @click="getOpenModal(reply)">
        <div class="detail">
            <h4>{{reply.lesson.name}}</h4>
            <h5>{{reply.teacher!=null?reply.teacher.firstName+' '+reply.teacher.lastName:'Öğretmen Ataması Henüz Yapılmadı'}}</h5>
            <p>{{reply.definiton}}</p>
            <span><i v-bind:style="{backgroundColor: getStatusColor(reply.status)}"></i> {{getStatusText(reply.status)}}</span>
        </div>
        <div class="cover">
            <img :src="reply.question.url" alt="">
        </div>
    </div>
</div>

<Modal v-model="isOpen" :fullscreen="false" modal-transition="scale" :click-out="true" :disable-motion="false" max-width="800px" :remove-backdrop="false">
    <div class="video-questions-modal">
        <ul>
            <li v-bind:class="!isSolution?'active':null" @click="isSolution=false">Soru</li>
            <li v-bind:class="isSolution?'active':null" @click="isSolution=true" v-if="selectedData.status=='Answered'">Videolu Cevap</li>
        </ul>
        <div class="question-box">
            <div class="question-answer-reply" v-if="!isSolution">
                <div class="detail">
                    <h4>{{selectedData.lesson.name}}</h4>
                    <h5>{{selectedData.teacher!=null?selectedData.teacher.firstName+' '+selectedData.teacher.lastName:'Öğretmen Ataması Henüz Yapılmadı'}}</h5>
                    <p>{{selectedData.definiton}}</p>
                </div>
                <div class="cover">
                    <img :src="selectedData.question.url" alt="">
                </div>
            </div>
            <div class="video" v-if="isSolution">
                <img :src="selectedData.solution.url" v-if="getType()=='image'" />
                <video :src="selectedData.solution.url" controls v-if="getType()=='video'" />
            </div>
        </div>
    </div>
</Modal>
</template>

<script>
import { Modal } from "vue-neat-modal";
var emitter = require('tiny-emitter/instance');
export default {
    components: {
        Modal
    },
    data() {
        return {
            replys: [],
            isOpen: false,
            isSolution: false,
            selectedData: {}
        }
    },
    created() {
        this.getData();
        var scoped = this;
        emitter.on('ReplyAdded', function () {
            scoped.getData();
        });
    },
    methods: {
        getType() {
            var mimeData = this.selectedData.solution.url.split('.');
            var mime = mimeData[mimeData.length - 1];
            switch (mime) {
                case "mp4":
                    return "video";
                case "avi":
                    return "video";
                case "mkv":
                    return "video";
                default:
                    return "image";
            }
        },
        getOpenModal(reply) {
            this.isSolution = false;
            this.selectedData = reply;
            this.isOpen = true;
        },
        addReply() {
            emitter.emit('SettingsOpen', 'AddReply');
        },
        getStatusColor(status) {
            switch (status) {
                case 'Delivered':
                    return '#f45d22';
                case 'Waiting':
                    return '#3333ff';
                case 'Answered':
                    return '#17bf63';
                case 'NotAnswered':
                    return '#ff003a';
                case 'Canceled':
                    return '#ff003a';
            }
        },
        getStatusText(status) {
            switch (status) {
                case 'Delivered':
                    return 'Gönderildi';
                case 'Waiting':
                    return 'Bekleniyor';
                case 'Answered':
                    return 'Cevaplandı';
                case 'NotAnswered':
                    return 'Cevaplanamadı';
                case 'Canceled':
                    return 'İptal Edildi';
            }
        },
        async getData() {
            this.$center.emit('Loading', 'show');
            var reply = await this.$http.get("Student/Reply");
            this.replys = reply.data.data;
            this.$center.emit('Loading', 'hide');
        },
    }
}
</script>
