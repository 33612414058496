<template>
  <div class="content quizzes missing">
    <div class="sidebar" style="left: 100%" id="missing-filter">
      <div class="quizzes-title">
        <i class="far fa-chevron-left" @click="openFilter()"></i>
        <h2>Eksiklerime dön</h2>
      </div>
      <div class="quizzes-filter">
        <filter-area
          ref="filterComp"
          css_class="select-box"
          @FilterExecute="filterExecute"
        />
      </div>
    </div>

    <div class="main">
      <div class="list-group">
        <div
          class="quizzes-item"
          v-for="homeWork in homeWorks"
          :key="homeWork.homeWorkId"
        >
          <div class="info">
            <h2 class="title">{{ homeWork.name }}</h2>
            <h4 class="title">{{ homeWork.definition }}</h4>
            <p class="date">
              {{ formatterDate(homeWork.startDate, 0)
              }}<span>{{ formatterDate(homeWork.startDate, 1) }}</span>
            </p>
            <p class="date">
              {{ formatterDate(homeWork.endDate, 0)
              }}<span>{{ formatterDate(homeWork.endDate, 1) }}</span>
            </p>
            <button
              v-if="homeWork.status == 'Waiting'"
              class="button success"
              style="padding: 5px; width: 100%"
              @click="openExam(homeWork)"
            >
              {{
                homeWork.type == "Test"
                  ? "Test'i Çözmeye Başla"
                  : "Videoyu İzle"
              }}
            </button>
            <button
              v-if="homeWork.status == 'Done'"
              class="button"
              style="
                padding: 5px;
                width: 100%;
                background-color: var(--bs-gray-500);
              "
            >
              Yapıldı
            </button>
            <button
              v-if="homeWork.status == 'NotDone'"
              class="button"
              style="padding: 5px; width: 100%; background-color: var(--bs-red)"
            >
              Yapılmadı
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-open-button">
      <i class="far fa-filter" @click="openFilter()"></i>
    </div>
  </div>
</template>

<script>
import FilterComponent from "../components/FilterComponentHomework.vue";
export default {
  components: {
    "filter-area": FilterComponent,
  },
  data() {
    return {
      filter: [],
      homeWorks: [],
      oldHomeWorks: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    formatterDate(date, index) {
      date = date.replaceAll("-", ".");
      date = date.split(" ");
      return date[index];
    },
    convertToDate(date) {
      var d = date.split(" "); // d-m-y h:i
      var dh = d[1].split(":"); // d-m-y h:i
      var dmy = d[0].split("-"); // d-m-y
      return new Date(dmy[2], parseInt(dmy[1]) - 1, dmy[0], dh[0], dh[1]);
    },
    async getData() {
      this.$center.emit("Loading", "show");
      var homeWorks = await this.$http.get("Student/HomeWork");
      this.homeWorks = homeWorks.data.data;
      this.oldHomeWorks = homeWorks.data.data;
      this.$refs.filterComp.executeFilterUrl();
      this.$center.emit("Loading", "hide");
      this.filterExecute({
        status: 0,
        lesson: 0,
        childLesson: 0,
        topic: 0,
        videoGroup: 0,
      });
    },
    openExam(homeWork) {
      console.log(homeWork);
      if (homeWork.type == "Test") {
        this.$router.push({
          name: "ExamPlayer",
          params: {
            examId: homeWork.test.id,
          },
        });
      } else {
        this.$router.push(
          "/watching/" +
            homeWork.topicId +
            "/" +
            homeWork.videoGroupId +
            "/video/" +
            homeWork.videoId +
            "?homeWorkId=" +
            homeWork.homeWorkId
        );
      }
    },
    openFilter() {
      var sideBar = document.getElementById("missing-filter");
      console.log(sideBar.style.left);
      if (sideBar.style.left == "100%") {
        sideBar.style.left = "0";
      } else if (sideBar.style.left == "0px") {
        sideBar.style.left = "100%";
      }
    },
    filterExecute(filter) {
      this.$center.emit("Loading", "show");
      var filteredData = this.oldHomeWorks;
      if (filter.lesson != 0)
        filteredData = filteredData.filter((x) => x.lessonId == filter.lesson);
      if (filter.childLesson != 0)
        filteredData = filteredData.filter(
          (x) => x.childLessonId == filter.childLesson
        );
      if (filter.topic != 0)
        filteredData = filteredData.filter((x) => x.topicId == filter.topic);
      if (filter.videoGroup != 0)
        filteredData = filteredData.filter(
          (x) => x.videoGroupId == filter.videoGroup
        );
      if (filter.videoGroup != 0)
        filteredData = filteredData.filter(
          (x) => x.videoGroupId == filter.videoGroup
        );
      if (filter.status == 0) {
        filteredData = filteredData.filter((x) => x.status == "Waiting");
      }
      if (filter.status == 1) {
        filteredData = filteredData.filter((x) => x.status == "NotDone");
      }
      if (filter.status == 2) {
        filteredData = filteredData.filter((x) => x.status == "Done");
      }
      this.homeWorks = filteredData;
      this.$center.emit("Loading", "hide");
    },
  },
};
</script>
