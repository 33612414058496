<template>
<div class="alert-group" id="alert-panel" v-bind:style="notifications.length==0 ? 'display:none' : 'display:flex'">
    <alert-item @remove="removeItem" v-for="not in notifications" :key="not.key" :id="not.key" :description="not.description || ''" :title="not.title || ''" :icon="not.icon || 'fas fa-info'" :type="not.type || 'info'" />
    <!-- <alert-item description="Yönlendiriliyorsunuz.." title="Giriş Başarısız!" icon="far fa-check" type="success" />
    <alert-item description="Yönlendiriliyorsunuz.." title="Giriş Başarısız!" icon="fas fa-info" type="info" />
    <alert-item description="Yönlendiriliyorsunuz.." title="Giriş Başarısız!" icon="fas fa-exclamation" type="warning" /> -->
</div>
</template>

<script>
import Alert from "./Alert.vue"
var emitter = require('tiny-emitter/instance');
export default {
    components: {
        "alert-item": Alert
    },
    data() {
        return {
            notifications: []
        }
    },
    created() {
        var scope = this;
        emitter.on('alert', function (arg1) {
            scope.addNotification(arg1);
        });
    },
    methods: {
        addNotification(notification) {
            notification.key = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
            this.notifications.push(notification);
        },
        removeItem(key) {
            var notification = this.notifications.filter(x => x.key == key)[0];
            var index = this.notifications.indexOf(notification);
            this.notifications.splice(index, 1);
        }
    }
}
</script>
