import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Lessons from "@/views/Lesson.vue";
import Exams from "@/views/Exam.vue";
import ReWatch from "@/views/ReWatch.vue";
import ExamPlayer from "@/views/ExamPlayer.vue";
import TrialExamPlayer from "@/views/TrialExamPlayer.vue";
import Login from "@/views/Auth/Login.vue";
import Profile from "@/views/Auth/Profile.vue";
import Notifications from "@/views/Auth/Notifications.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";
import Recovery from "@/views/Auth/Recovery.vue";
import Target from "@/views/Target.vue";
import AddTarget from "@/views/AddTarget.vue";
import Missing from "@/views/Missing.vue";
import Badge from "@/views/Badge.vue";
import Report from "@/views/Report.vue";
import Statistics from "@/views/Statistics.vue";
import Watching from "@/views/LessonWatch.vue";
import Guidance from "@/views/Guidance.vue";
import Message from "@/views/Message.vue";
import Cddo from "@/views/Cddo.vue";
import Reply from "@/views/Reply.vue"
import Calendar from "@/views/Calendar.vue";
import HomeWork from "@/views/HomeWork.vue";


import Survey from "@/views/Survey.vue";
import SurveyWatching from "@/views/SurveyWatchingTeacher.vue";

//Watching
import Video from "@/views/Watching/Video.vue"
import Document from "@/views/Watching/Document.vue"
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "default",
      authRequire: true
    }
  },
  {
    path: "/survey",
    name: "Survey",
    component: Survey,
    meta: {
      layout: "",
      authRequire: true
    }
  },
  {
    path: "/watchSurvey/:id",
    name: "WatchSurvey",
    component: SurveyWatching,
    meta: {
      layout: "",
      authRequire: false
    }
  },
  {
    path: "/lesson",
    name: "Lessons",
    component: Lessons,
    meta: {
      layout: "default",
      authRequire: true
    }
  },
  {
    path: "/watching/:topicId/:videoGroupId",
    name: "Watching",
    component: Watching,
    meta: {
      layout: "watch",
      authRequire: true
    },
    children: [
      {
        name: 'video',
        path: 'video/:elementId',
        component: Video
      },
      {
        name: 'document',
        path: 'document/:elementId',
        component: Document
      }
    ]
  },
  {
    path: "/exams",
    name: "Exams",
    component: Exams,
    meta: {
      layout: "default",
      authRequire: true
    }
  },
  {
    path: "/reWatch",
    name: "Watch",
    component: ReWatch,
    meta: {
      layout: "default",
      authRequire: true
    }
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: {
      layout: "default",
      authRequire: true
    }
  },
  {
    path: "/exam/:examId",
    name: "ExamPlayer",
    component: ExamPlayer,
    meta: {
      layout: "exam",
      authRequire: true
    }
  },
  {
    path: "/trialexam/:examId",
    name: "TrialExamPlayer",
    component: TrialExamPlayer,
    meta: {
      layout: "exam",
      authRequire: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "none",
      authRequire: false
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      layout: "none",
      authRequire: false
    }
  },
  {
    path: "/recovery/:code",
    name: "Recovery",
    component: Recovery,
    meta: {
      layout: "none",
      authRequire: false
    }
  },
  {
    path: "/me",
    name: "Profile",
    component: Profile,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notifications,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/target",
    name: "Targets",
    component: Target,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/add-target",
    name: "AddTarget",
    component: AddTarget,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/missing",
    name: "Missings",
    component: Missing,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/homework",
    name: "Homework",
    component: HomeWork,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/badge",
    name: "Badges",
    component: Badge,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/report",
    name: "Reports",
    component: Report,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/statistic",
    name: "Statistics",
    component: Statistics,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/guidance",
    name: "Guidance",
    component: Guidance,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/message",
    name: "Messages",
    component: Message,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/cddo",
    name: "Cddo",
    component: Cddo,
    meta: {
      layout: "default",
      authRequire: false
    }
  },
  {
    path: "/reply",
    name: "Replys",
    component: Reply,
    meta: {
      layout: "default",
      authRequire: false
    }
  }
];

const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(),
  routes
});

export default router;
