<template>
  <header-bar />
  <nav-bar />
</template>

<script>
import HeaderBar from "./Header/HeaderBar"
import NavBar from "./Header/NavBar"
export default {
  components: {
    "header-bar": HeaderBar,
    "nav-bar": NavBar
  }
}
</script>
