<template>
<div class="content guidance">

    <div class="main">
        <div class="list-group">

            <div class="guidance-list-item" @click="addNewModal()">
                <div class="top">
                    <i class="fad fa-calendar-star"></i>
                    <div class="det">
                        <h5>Yeni Randevu</h5>
                        <p>Randevu Talep Et</p>
                    </div>
                    <i style="color: #17bf63;borderColor: #17bf63" class="fas fa-plus"></i>
                </div>
                <div class="bot">
                    <p>Bu Alandan Rehberlik Öğretmeninizden Randevu Talep Edebilirsiniz!</p>
                </div>
            </div>

            <div class="guidance-list-item" v-for="guidance in guidances" :key="guidance.id">
                <div class="top">
                    <i class="fad fa-calendar-star"></i>
                    <div class="det">
                        <h5>{{guidance.startDate}}</h5>
                        <p>{{guidance.name}}</p>
                    </div>
                    <i v-bind:style="{color: getStatusType(guidance.level),borderColor: getStatusType(guidance.level)}" class="fas fa-exclamation"></i>
                </div>
                <div class="bot">
                    <p v-html="guidance.note.length!=0?guidance.note:'Not Belirtilmemiş!'"></p>
                </div>
            </div>
            <!-- <div class="guidance-list-item"  >
                <router-link to="/cddo">
                <div class="top">
                    <i class="fad fa-calendar-star"></i>
                    <div class="det">
                        <h5>Cddo Anket Sonucu</h5>
                        <p>Anket Sonucumu Gör</p>
                    </div>
                    <i style="color: #17bf63;borderColor: #17bf63" class="fas fa-plus"></i>
                </div>
                <div class="bot">
                    <p>Bu Alandan Cddo Anket Sonucunuzu Görebilirsiniz</p>
                </div>
                </router-link>
            </div> -->
        </div>
    </div>

    <div class="sidebar" v-bind:style="{left: left}" v-if="isLoaded">
        <div class="guidance-title">
            <i class="far fa-chevron-left" @click="openSideBar()"></i>
            <h2>Devam durumu</h2>
        </div>
        <ul>
            <li>
                <span>#</span>
                <p>1</p>
                <p>2</p>
                <p>3</p>
                <p>4</p>
                <p>5</p>
                <p>6</p>
                <p>7</p>
                <p>8</p>
                <p>9</p>
                <p>10</p>
                <p>11</p>
                <p>12</p>
                <p>13</p>
                <p>14</p>
                <p>15</p>
                <p>16</p>
                <p>17</p>
                <p>18</p>
                <p>19</p>
                <p>20</p>
                <p>21</p>
                <p>22</p>
                <p>23</p>
                <p>24</p>
                <p>25</p>
                <p>26</p>
                <p>27</p>
                <p>28</p>
                <p>29</p>
                <p>30</p>
                <p>31</p>
            </li>
            <li v-for="mn in mounth" :key="mn.number">
                <span v-tooltip="mn.tooltip">{{mn.title}}</span>
                <i v-for="n in 31" :key="n" v-bind:style="{backgroundColor:getStatus(mn.number,n)}" v-tooltip.tooltip="getText(mn.number,n)"></i>
            </li>
        </ul>
    </div>

    <div class="filter-open-button" @click="openSideBar()">
        <i class="far fa-bars"></i>
    </div>

</div>
</template>

<script>
var emitter = require('tiny-emitter/instance');
export default {
    data() {
        return {
            guidances: [],
            left: '100%',
            mounth: [
                { title: 'OCA', tooltip: 'OCAK', number: 1 },
                { title: 'ŞUB', tooltip: 'ŞUBAT', number: 2 },
                { title: 'MAR', tooltip: 'MART', number: 3 },
                { title: 'NİS', tooltip: 'NİSAN', number: 4 },
                { title: 'MAY', tooltip: 'MAYIS', number: 5 },
                { title: 'HAZ', tooltip: 'HAZİRAN', number: 6 },
                { title: 'TEM', tooltip: 'TEMMUZ', number: 7 },
                { title: 'AGU', tooltip: 'AĞUSTOS', number: 8 },
                { title: 'EYL', tooltip: 'EYLÜL', number: 9 },
                { title: 'EKİ', tooltip: 'EKİM', number: 10 },
                { title: 'KAS', tooltip: 'KASIM', number: 11 },
                { title: 'ARA', tooltip: 'ARALIK', number: 12 },
            ],
            discontinuity: [],
            isLoaded: false
        }
    },
    async created() {
        this.getData();
        emitter.on('AddGuidance', (status) => {
            this.getData();
        });
    },
    methods: {
        async getData() {
            this.$center.emit('Loading', 'show');
            var guidance = await this.$http.get("Student/Interviews");
            this.guidances = guidance.data.data;
            var discontinuity = await this.$http.get("Student/Discontinuity");
            this.discontinuity = discontinuity.data.data;
            this.isLoaded = true;
            this.$center.emit('Loading', 'hide');
        },
        openSideBar() {
            this.left = this.left == '100%' ? '0%' : '100%';
        },
        getStatusType(level) {
            switch (level) {
                case 'Critical':
                    return "#ff003a"
                case 'High':
                    return "#f45d22"
                case 'Middle':
                    return "#ffad1f"
                case 'Low':
                    return "#17bf63"
                case 'Information':
                    return "#1790bf"
            }
        },
        getText(month, day) {
            var userStatus = this.discontinuity.filter(x => x.month == month && x.day == day);
            var strReturn = "";
            if (userStatus.length > 0) {
                userStatus.forEach(element => {
                    strReturn += element.title + ' ' + (element.isActive ? 'Katıldı' : 'Katılmadı') + '\n';
                });
                return strReturn;
            } else {
                return "Ders Yok";
            }
        },
        getStatus(month, day) {
            var userStatus = this.discontinuity.filter(x => x.month == month && x.day == day);
            if (userStatus.length > 0) {
                var istrue = false;
                userStatus.forEach(element => {
                    if (element.isActive == true)
                        istrue = true;
                });
                return istrue == true ? 'greenyellow' : 'red';
            } else {
                return null;
            }
        },
        addNewModal() {
            this.$center.emit('SettingsOpen', 'AddGuidance');
        }
    }
}
</script>
