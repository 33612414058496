<template>
<div class="lessons-watch-header" style="position:fixed">
    <div class="title">
        <i class="fad fa-home" style="font-size:22px;" @click="backToHome()"></i>
        <i class="far fa-angle-left" @click="backLesson()"></i>
        <h1>{{title}}</h1>
    </div>
    <div class="more">
        <span @click="showDialog=true">Eksiklerim</span>
        <i class="far fa-flag-alt" @click="addReport"></i>
        <i class="fas" v-bind:class="isFullScreen?'fa-compress':'fa-expand'" @click="fullScreen()"></i>
    </div>
    <Modal v-model="showDialog" :fullscreen="false" modal-transition="scale" :click-out="true" :disable-motion="false" max-width="500px" :remove-backdrop="false">
        <div class="theme-change" style="display: flex;max-width:500px">
            <div class="modal-header">
                <h3 class="title">Eksiklerim</h3>
            </div>
            <div class="con">
                <div class="list-group">
                    <div class="missing-item" v-for="video in lacksVideo" :key="video.id" @click="openWatching(video)">
                        <button>{{video.name}}</button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</div>
</template>

<script>
var emitter = require('tiny-emitter/instance');
import { Modal } from "vue-neat-modal";
export default {
    components: {
        Modal
    },
    data() {
        return {
            isFullScreen: false,
            title: '',
            showDialog: false,
            lacksVideo: [],
            returnUrl: ''
        }
    },
    created() {
        this.returnUrl = window.history.state.back;
        console.log(window.history);
        var scoped = this;
        emitter.on('TitleChanged', function (arg1) {
            scoped.title = arg1;
            scoped.getGains();
        });
    },
    methods: {
        backToHome() {
            this.$router.push({ name: "Home" });
        },
        backLesson() {
            this.$router.push(this.returnUrl);
        },
        fullScreen() {
            this.isFullScreen = !this.isFullScreen;
            this.$center.emit('FullScreen', this.isFullScreen);
        },
        getGains() {
            this.lacksVideo = [];
            this.$http.get("Student/LackVideo/" + this.$route.params.videoGroupId).then(x => {
                this.lacksVideo = x.data.data;
            }).catch(error => {
                //alert(error);
            });
        },
       async openWatching(element) {
          await this.$http.put("Student/EndLack/" + element.lackId);
            //this.$router.push('/watching/' + this.$route.params.topicId + '/' + this.$route.params.videoGroupId + '/video/' + elementId);
             window.location.href =('/watching/' + this.$route.params.topicId + '/' + this.$route.params.videoGroupId + '/video/' + element.id);
            this.showDialog = false;
        },
        addReport() {
            this.$center.emit('SettingsOpen', "AddReport");
        },
    },
}
</script>
